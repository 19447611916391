import { Component, OnInit } from '@angular/core';
import { IContentItem } from '../services/IContentItem';
import { DatabaseKidContentService } from '../services/database-kid-content.service';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-content-display-spy-bs4',
  templateUrl: './content-display-spy-bs4.component.html',
  styleUrls: ['./content-display-spy-bs4.component.scss']
})
export class ContentDisplaySpyBs4Component implements OnInit {

  mItems: IContentItem[];


  constructor(aDatabase: DatabaseKidContentService) {
    this.mItems = aDatabase.getItems();

   }

    removeSpaces(aSomeString): string{
      return aSomeString.split(' ').join('');
    }

    ngOnInit() {
    }

    getResourceButtonTitle(aResourceType: string): string {
      return  aResourceType === 'v'  ? 'Watch this video' : 'Check it out';

      // {{sectionItem.resourc == 'v'  ? 'Watch this video' : 'Check it out'}}
    }


  } //:end
