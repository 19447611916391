import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-spy-bs4',
  templateUrl: './scroll-spy-bs4.component.html',
  styleUrls: ['./scroll-spy-bs4.component.css']
})
export class ScrollSpyBs4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
