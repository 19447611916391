import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';


import { HomePageComponent } from './app-components/home-page/home-page.component';
import { HomePageContentComponent} from './app-components/home-page-content/home-page-content.component';

import { HomePageHeaderComponent} from './app-components/home-page-header/home-page-header.component';
import { HomePageFooterComponent} from './app-components/home-page-footer/home-page-footer.component';

import { HomePageMenuTopComponent} from './app-components/home-page-menu-top/home-page-menu-top.component';
import { HomePageMenuLeftNavComponent } from './app-components/home-page-menu-left-nav/home-page-menu-left-nav.component';

import { ContentSectionComponent} from './app-components/content-section/content-section.component';
import { ContentSectionArticleComponent} from './app-components/content-section-article/content-section-article.component';

import { ContentFormTemplateComponent} from './app-components/content-form-template/content-form-template.component';

import { ContentDisplayComponent } from './content-display/content-display.component';
import { ContentItemComponent } from './content-item/content-item.component';
import { ScrollSpyDemoComponent } from './scrollSpyDemo/scrollSpyDemo.component';
import { ScrollSpyBs4Component } from './scroll-spy-bs4/scroll-spy-bs4.component';
import { ScrollSpyWc3Bs4Component } from './scroll-spy-wc3-bs4/scroll-spy-wc3-bs4.component';
import { ContentDisplaySpyBs4Component } from './content-display-spy-bs4/content-display-spy-bs4.component';



import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';



import { ImageFlipComponent } from './image-flip/image-flip.component';
import { MenuMaxComponent } from './menu-max/menu-max.component';


@NgModule({
  declarations: [
      AppComponent,

      HomePageComponent,
      HomePageContentComponent,
      HomePageHeaderComponent,
      HomePageFooterComponent,
      HomePageMenuTopComponent,
      HomePageMenuLeftNavComponent,
      ContentSectionComponent,
      ContentSectionArticleComponent,

      ContentDisplayComponent,
      ContentItemComponent,
      ScrollSpyDemoComponent,
      ScrollSpyBs4Component,
      ScrollSpyWc3Bs4Component,
      ContentDisplaySpyBs4Component,
      ImageFlipComponent,
      MenuMaxComponent,

      ContentFormTemplateComponent

   ],
  imports: [

  BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconLib: FaIconLibrary){
    iconLib.addIcons(faBook, faPlay, faSquare, faCheckSquare, faBars);
  }


}
