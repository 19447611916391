<div class="topnav menuMax" id="myTopnav">
  <a href="#home" class="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>

  <a href="#about">About</a>

  <a href="javascript:void(0);" class="icon" onclick="myFunction()">
    <div style="font-size:15px;color:white">
      <fa-icon [icon]="['fas', 'bars']" size="2x"></fa-icon>
    </div>
  </a>
</div>
