<p>
  content-form-template works!
</p>


<form>

  <input type="text" placeholder="section" /> <br>
  <input type="text" placeholder="url" /> <br>
  <input type="text" placeholder="author" /> <br>
  <input type="text" placeholder="graphic" /> <br>
  <input type="text" placeholder="description" /> <br>
  <input type="text" placeholder="focus" /> <br>
  <input type="text" placeholder="sharpFocus" /> <br>

  <textarea rows="7" cols="40"></textarea> <br>
  <input type="button" value="Submit" onclick="fnSubmit()"> <br>

</form>


