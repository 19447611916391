<div class="sidenav">

  <a href="#">Link 1</a>
  <a href="#">Link 2</a>
  <a href="#">Link 3</a>
  <a href="#">Link 4</a>
  <a href="#">Link 5</a>
  <a href="#">Link 6</a>
  <a href="#">Link 7</a>
  <a href="#">Link 8</a>
  <a href="#">Link 9</a>
  <a href="#">Link 10</a>

  <a href="#">Link 1</a>
  <a href="#">Link 2</a>
  <a href="#">Link 3</a>
  <a href="#">Link 4</a>
  <a href="#">Link 5</a>
  <a href="#">Link 6</a>
  <a href="#">Link 7</a>
  <a href="#">Link 8</a>
  <a href="#">Link 9</a>
  <a href="#">Link 10</a>

</div>
