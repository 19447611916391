<div class="navbar" id="myTopnav">
  <a href="#home" class="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
  <a href="#about">About</a>
  <!--
      <a href="javascript:void(0);" class="icon" onclick="myFunction()">
      <i class="fa fa-bars"></i>
      </a>
  -->
  <div class="icon" style="font-size:15px;color:white">
    <fa-icon [icon]="['fas', 'bars']" size="2x"></fa-icon>
  </div>
</div>


<!--

  <script>
  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  </script>
-->
