import { Component, OnInit } from '@angular/core';
import { IContentItem } from '../services/IContentItem';
import { DatabaseKidContentService } from './../services/database-kid-content.service';


@Component({
  selector: 'app-content-display',
  templateUrl: './content-display.component.html',
  styleUrls: ['./content-display.component.css']
})
export class ContentDisplayComponent implements OnInit {


  mItems: IContentItem[];

  constructor(aDatabase: DatabaseKidContentService) {
    this.mItems = aDatabase.getItems();
  }

  ngOnInit(): void {
  }
}




// sectionItems: { description: string, link: string, subject: string }


