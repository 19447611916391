





<!-- <app-menu-max></app-menu-max> -->


<div class="container-fluid">
  <!-- Row -->



  <div class="row">

        <!-- STANDARD NAV LIST V01  -->
        <nav class="col-sm-2  navBar" id="myScrollspy">
          <ul class="nav nav-pills flex-column">
            <li class="nav-item">
              <a class="nav-link info" href="#home">Home</a>
            </li>

            <li class="nav-item" *ngFor="let theItem of mItems">
              <a class="nav-link info" href=#{{theItem.sectionID}} >{{theItem.sectionTitle}}</a>
            </li>
          </ul>
        </nav>





        <!-- CONTENT  -->
        <!-- <div class="col-sm-11 col-8"> -->
        <div class="container">


          <div id="home">
            <div class="card">
              <div class="card-body">
                <h2>KidPower.fun</h2>
                <H2><i>Aim HIGH!</i></H2>

                <h3>Below are resource links to help your family<br/>
                  - try new things<br/>
                  - take risks<br/>
                  - listen<br/>
                  - study<br/>
                  - learn<br/>
                  - focus<br/>
                  - grow strong, find meaning and have more fun!  :)
                </h3>

                <br>
                <br>
                <h3><i>Alwasy find a way to stay in the game!</i></h3>

                <h3><i>Keep exploring, learning, practicing and working to become better!</i></h3>

                <br>
                <br>

                <div class="titleText">
                    <h4>Please send ideas for more features and content - thanks for your help!! </h4>

                    <h4>Also, please bear with me - I am creating this site with my spare time of which I have zero...

                        <br><br>Best,

                        <br><br>Thomas  :)
                    </h4>
                </div>

                <br>
              </div>
            </div>
          </div>


           <!-- SECTION TITLES -->
            <div id={{theItem.sectionID}} class="accordion" *ngFor="let theItem of mItems">

                    <h1 class="text-primary">{{theItem.sectionTitle}}</h1>

                      <!-- SECTION CONTENT -->
                      <div class="flex-container">

                          <!-- <div class="card-body card-body-primary"> -->
                            <h2 class="text-secondary">{{theItem.sectionSubTitle}}</h2>
                            <h2 class="text-secondary"><i>{{theItem.sectionDescription}}</i></h2>
                            <h3 class="text-secondary"><i>{{theItem.sectionFocus}}</i></h3>


                            <div class="row">

                              <div class="card card-outline" style="width: 20rem;" *ngFor="let sectionItem of theItem.sectionItems">


                                           <!-- imageOver <img src="assets/images/everyKidBJJ.jpg" alt="Avatar" style="width:300px;height:300px;"> -->
                                          <a href="{{sectionItem.link}}" target="_blank">
                                            <img class="card-img-top" src="assets/images/{{sectionItem.imgPath}}.jpg" title="Click image to {{getResourceButtonTitle(sectionItem.resourceType).toLowerCase()}}" alt="">
                                            <div class="imageButton">Watch this video</div>
                                          </a>


                                         <!--

                                            <a href="{{sectionItem.link}}" target="_blank">
                                            <div class="flip-card-back">
                                            <h3>{{sectionItem.title}}</h3>
                                            <p>Click to</p>
                                            <p>{{getResourceButtonTitle(sectionItem.resourceType)}}</p>
                                            </div>
                                          </a>




                                          -->

                                <div class="card-body">
                                  <h4 class="card-title  text-primary">{{sectionItem.title}}</h4>
                                  <h6 class="card-text  text-primary">{{sectionItem.description}}</h6>
                                  <h6 class="card-text subtitle"><i>{{sectionItem.focus}}</i></h6>
                                  <h6 class="card-text subtitle"><i><b>{{sectionItem.sharpFocus}}</b></i></h6>
                                  <a href="{{sectionItem.link}}" target="_blank" class="btn btn-primary linkButton-bottom" title="Click to {{getResourceButtonTitle(sectionItem.resourceType).toLowerCase()}}">{{getResourceButtonTitle(sectionItem.resourceType)}}</a>
                                </div>
                                <br>
                              </div>





                              <!--
                                  <div class="card"  *ngFor="let sectionItem of theItem.sectionItems">
                                    <h6 class="card-title"> {{sectionItem.description}}</h6>
                                  </div>
                              -->
                          <!-- </div> -->

                    </div>




                    <!-- HABIT SPECIAL CONTENT - D=FREEDOM -->
                    <div *ngIf="theItem.sectionID == 'habits'">

                      <div class="box">
                        <div>
                                <a name="disciplineequalsfreedom">
                                <h2 class="highlight02 inline" title="In other words, if you show up, focus and do a good job, you will earn more freedom over a period of time.">
                                    Discipline equals freedom!</h2>
                                <br>
                                    <h5>( A powerful phrase regarding good habits from Jocko's Warrior Kid books ) </h5>
                                <br>
                                <p>In other words, if you work to develop and maintain the habit of self-discipline, focus and consistently do a good job, you will become more successful in your endeavors and earn more freedom as you move forward in life.
                                    <br><br>
                                    So, the next time you are faced with doing a task that... perhaps you are not very excited about and yet, the task needs to be done ASAP!
                                    <br>
                                    Just tell your self ( <i>over and over again</i> )... <strong>"Discipline equals freedom" and <i>visualize your success and freedom!</i></strong> <br>
                                    <br>
                                    <br>
                                    Then step up and knockout the thing you need to do.
                                    <br><br>
                                    </p><div class="itemTitle">KA-BOOM!</div><br>

                                    <i>You are now free from that task!</i><br><br>

                                    This habit of task complition will act like a rocket and propell your life forward!

                                    <br><br>
                                    Furthermore, as you develop and refine your new habit by applying this method and others
                                    to help you consistently complete more tasks,
                                    you will create a "positive feedback loop" and enjoy greater success and freedome.

                                    <br><br><br>
                                     <i><u>The future you will very much appreciate your efforts and think you are AWESOME!</u></i>
                                     <br><br>
                                     <br><br>

                                     <div class="itemTitle">"Positive feedback loop"</div><br>
                                     Positive feedback loops enhance / amplify changes.<br>
                                     The more consistent and focused you become, the more success and freedom you will earn!
                                <p></p>
                                </a><a href=""></a>

                        </div>
                      </div>
                      <br><br>
                    </div> <!-- END: HABIT SPECIAL CONTENT - D=FREEDOM -->


                    <!-- WARRIOR KID SPECIAL CONTENT - YOUNG KIDS -->
                    <div *ngIf="theItem.sectionID == 'warrior-kid'">
                          <div class="box">
                              <div class="itemTitle">A few Notes on the book: 'The way of the warrior kid'</div>
                              <br>
                              <ul style="list-style-type:disc;">
                                <li><div class="itemTitle">It is best to get the physical books and the audio editions.  They are both outstanding!</div>
                                  They are perfect for listening in the car.  Each chapter is only about 5 min so, they are just right for quick errands!
                                  Also, on a longer road trip, each chapter provides plenty of opportunities to interact with your kids!
                                  Answer their questions, discus various problem-solving ideas, and have discussion regarding good and bad habits etc.
                                  The voices of Mark, his friends, and Uncle Jake are fantastic!
                                  The best thing is to get the audio for the car and the physical books to read with your kids and view the
                                  excellent illustrations in the physical books.  As expected, the coloring book has does an especially nice job of telling the story with pictures! :)</li>
                              </ul>

                              <ul style="list-style-type:disc;">
                                      <li><div class="itemTitle">Momentum </div>The story gains a lot of momentum in subsequent chapters as the main character Mark works with his uncle Jake to overcome many problems &amp; challenges. Stick with it - it is an awesome story and message! Be sure to push past the first few chapters because Uncle Jake shows Mark the path and Mark gets busy working hard to solve problems 1-2-3!</li>
                              </ul>

                              <ul style="list-style-type:disc;">
                                      <li><div class="itemTitle">Attention span:</div>  For young kids ( under about 7 ) there are two parts in the book that may require a larger attention span... </li>
                              </ul>
                                  <ol class="sublist">
                                      <li>In the beginning of the book, many fantastic leadership principles are covered.  However, I have found that some young readers / listeners may "checkout" during this part as they may be a little young to connect the dots. </li>
                                      <li>Later in the book, there is a simmilar situation when <a href="https://www.youtube.com/watch?v=qhMsQm0dufU" title="How Lincoln Changed the World in Two Minutes">the Gettysburg Address </a> is covered. &nbsp; Kids under the age of about 7, may not understand or have an appreciation for this info just yet.</li>

                                  </ol>

                              <ul style="list-style-type:none;">
                                      <li>Consider listening to these parts and just fast forwared to the next section/chapter if your kids are not ready for this deeper content. </li>
                                      <li>Then, revisit this most important information when the time is right.</li>
                                      <li>&nbsp;</li>
                                      <li><div class="itemTitle"><i>Everything else is fantastic for kids 4 and up! :) </i></div> </li>

                              </ul>
                              <br>
                          </div>

                          <br><br>

                    </div> <!-- END: WARRIOR KID SPECIAL CONTENT -->


              </div>

            </div>
            <!-- END:SECTION TITLES -->
            <br><br>

          </div>
      </div>



<!--

<div class="card" *ngFor="let theItem of mItems">
    <div class="card-header">
      {{theItem.sectionTitle}}
    </div>

    <div class="card-body">
        <h5 class="card-title">{{theItem.sectionSubTitle}}</h5>
        <p class="card-text">{{theItem.sectionDescription}}</p>
    </div>

</div>
-->
