import { Component, OnInit } from '@angular/core';

import { DatabaseKidContentService } from './../services/database-kid-content.service';
import { IContentItem } from './../services/IContentItem';

@Component({
  selector: 'app-scroll-spy-wc3-bs4',
  templateUrl: './scroll-spy-wc3-bs4.component.html',
  styleUrls: ['./scroll-spy-wc3-bs4.component.css']
})
export class ScrollSpyWc3Bs4Component implements OnInit {

  mItems: IContentItem[];

  constructor(aDatabase: DatabaseKidContentService) {
    this.mItems = aDatabase.getItems();
  }

  ngOnInit() {

  }

}
