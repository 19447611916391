



<app-home-page-header></app-home-page-header>

<app-home-page-menu-top></app-home-page-menu-top>

<app-home-page-menu-left-nav></app-home-page-menu-left-nav>

<app-home-page-content></app-home-page-content>

<app-home-page-footer></app-home-page-footer>


<script>
window.onscroll = function() {myFunction()};

var navbar = document.getElementById("navbar");
var lSideNavBar = document.getElementById("sideNavBarID")
var sticky = navbar.offsetTop;

function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
   // lSideNavBar.classList.add("sticky");
  } else {
    navbar.classList.remove("sticky");
  //  lSideNavBar.classList.remove("sticky");
  }
}
</script>
