<p>
  image-flip works!
</p>

<!--

<div class="flip-card">
  <div class="flip-card-inner">
      <div class="flip-card-front">

        This line may not be required --- will need to fix when I implement this component.
        -------
        <img src="assets/images/everyKidBJJ.jpg" alt="Avatar" style="width:300px;height:300px;">
        -------
        <a href="{{sectionItem.link}}" target="_blank">
          <img class="card-img-top imageOver" src="assets/images/{{sectionItem.imgPath}}.jpg" alt="Card image cap">
          <div class="imageButton">Watch this video</div>
      </a>
      </div>


        <a href="{{sectionItem.link}}" target="_blank">
          <div class="flip-card-back">
            <h3>{{sectionItem.title}}</h3>
            <p>Click to</p>
            <p>{{sectionItem.medium == 'v'  ? 'Watch this video' : 'Check it out'}}</p>

          </div>
        </a>
  </div>
</div>

-->

