
<div class="container-fluid">

  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" height="500rem" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1760d1bd650%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1760d1bd650%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22217.7609375%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" height="500rem" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1760d117f27%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1760d117f27%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3125%22%20y%3D%22217.7609375%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" height="500rem" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1760d117f27%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1760d117f27%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3125%22%20y%3D%22217.7609375%22%3EThird slide%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>





<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link active" href="#">Active</a>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
    <div class="dropdown-menu">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something else here</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="#">Separated link</a>
    </div>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Link</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled" href="#">Disabled</a>
  </li>
</ul>











<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1>Kid Power</h1>


    <H2><i>Aim HIGH!</i></H2>

    <h3>Resources to help your family try new things, take risks, focus, listen, study, experiment and work hard to grow strong and have more fun!  :)</h3>


    <h4>Please send ideas for more features and content - thanks for your help!! </h4>
    <h4>Also, please bear with me - I am creating this site with my spare time of which I have zero...

        <br><br>Best,

        <br><br>Thomas </h4>

    <br>


    <p class="lead"></p>
  </div>
</div>




<div class="card" *ngFor="let theItem of mItems">
  <div class="card-header">
    H{{theItem.sectionTitle}}
  </div>
  <div class="card-body">

      <h5 class="card-title">{{theItem.sectionSubTitle}}</h5>
      <p class="card-text">{{theItem.sectionDescription}}</p>



      <div class="container-fluid">
        <div class="row">
          <div class="col-lg" *ngFor="let sectionItem of theItem.sectionItems">

              <div>
                <div class="card" style="width: 20rem; background-color: blueviolet;">

                      <a href="{{sectionItem.link}}" target="_blank">
                        <img class="card-img-top" src="https://via.placeholder.com/200x115" alt="Card image cap">
                      </a>
                        <div class="card-body">
                          <h6 class="card-title"> {{sectionItem.description}}</h6>
                          <p class="card-text">some text...</p>

                    <a href="{{sectionItem.link}}" class="btn btn-primary" target="_blank">Watch this video</a>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>



  </div>
</div>





    <!--

      <div id="Item1" class="container-fluid">
      <ul style="list-style-type:none;">
        <li *ngFor="let theItem of mItems">
          <br><br>
          <div class="alert alert-secondary" role="alert">
          <h2>{{theItem.sectionTitle}}</h2>
        </div>
          <p>{{theItem.sectionDescription}}</p>
          <hr>

          <div class="row">
            <div class="col-lg" *ngFor="let sectionItem of theItem.sectionItems">
              <div>
                  <div class="card" style="width: 30rem;">

                        <a href="{{sectionItem.link}}" target="_blank">
                          <img class="card-img-top" src="https://via.placeholder.com/300x150" alt="Card image cap">
                        </a>
                          <div class="card-body">
                            <h5 class="card-title"> {{sectionItem.description}}</h5>
                            <p class="card-text"></p>

                      <a href="{{sectionItem.link}}" class="btn btn-primary" target="_blank">Watch this video</a>
                    </div>
                  </div>
              </div>


            </div>
          </div>

        </li>
      </ul>

    </div>

  -->

