
<!-- <app-content-display></app-content-display> -->

<!-- <app-scroll-spy-wc3-bs4></app-scroll-spy-wc3-bs4> -->




<app-content-display-spy-bs4></app-content-display-spy-bs4>


<!-- <app-content-form-template></app-content-form-template> -->

<!--
<app-home-page></app-home-page>

-->

<router-outlet></router-outlet>

<footer>version 2021.02.19.2.14 - I will be adding more content and improvements as I get the chance - <b class="power"><i>Now... let's Get after it!&nbsp; :)</i></b>  </footer>
