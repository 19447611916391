import { Injectable } from '@angular/core';
import { IContentItem } from './IContentItem';

// U.S. ARMY RANGER WATER JUMP
// https://www.youtube.com/watch?v=XbcrCpAdamc

@Injectable({
  providedIn: 'root'
})
export class DatabaseKidContentService {

constructor() { }

    getItems(): IContentItem[] {
      return [
        {
          sectionID: 'top-pics',
          sectionTitle: 'Top Pics',
          sectionSubTitle: 'The best place to start - checkout these inspiring videos!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '( just click on the picture to watch the video )',
          sectionCategory : 'BJJ',
          sectionItems : [
                            {resourceType: 'v', title: 'Ruotolo Twins',  author: '', focus: 'An awesome positive mental attitude and perspective on competition.', sharpFocus: '', description : 'Inspired By results!', link: 'https://www.youtube.com/watch?v=0lQm2pcTaYo', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotolotTwinsInspiredByResults'},
                            {resourceType: 'v', title: 'Akira', author: 'Akira', focus: 'She does a great job explaining her journey and highlights humility.  \"win some, learn some\"', sharpFocus: 'Above all - stay in the game! :)', description : 'Checkout Akira!  She is a 12-year-old Jiu-Jitsu champion!',  link: 'https://www.youtube.com/watch?v=1DmoNf8m1VI', category: 'bjj1', buttonTitle: '',  imgPath: '12YearOldBJJ' },
                            {resourceType: 'v', title: 'The Game', author: 'Jocko',
                            focus: 'Playing “the game of life” with integrity to become a TRUE champion! :)', sharpFocus: '', description : 'The best place to start!  If you like this story, definitely checkout Jocko\'s books videos!', link: 'https://www.youtube.com/watch?v=7hT2CYFMMJs&t=229s', category: 'bjj', buttonTitle: 'Watch this video', imgPath: 'jocko_thegame'},
                            


                            // Playing Jiu-Jitsu With Your Kids
                            // https://www.youtube.com/watch?v=4fjdQF7zlcI&t=8s
                            
                            
                            // How to Play Jiu-Jitsu With Your Kids Part 8: How to Fight Your 5 Year Old
                            // https://www.youtube.com/watch?v=8utnJb-PJJk&t=34s
                            

                            // How to play jiu-jitsu with your kids Part 1: Guard recovery development
                            // https://www.youtube.com/watch?v=9bOC1gE4G9E&t=123s


                            // How to Play Jiu-Jitsu With Your Kids Part 4: Grandmaster Style!
                            // https://www.youtube.com/watch?v=LPwYqOetpn8&t=29s


                            //https://www.youtube.com/results?search_query=How+to+play+jiu-jitsu+with+your+kids+Part+Gracie+Ohio

                            

                            {resourceType: 'v', title: 'Playing Jiu-Jitsu With Your Kids',  author: 'Gracie', focus: '', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=4fjdQF7zlcI&t=8s', category:'BJJ Kids', buttonTitle: '', imgPath: 'BJJ-Kids-Play01' },
                            {resourceType: 'v', title: 'How to Play Jiu-Jitsu With Your Kids Part 4: Grandmaster Style!',  author: 'Gracie', focus: '', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=LPwYqOetpn8&t=29s', category:'BJJ Kids', buttonTitle: '', imgPath: 'BJJ-Kids-Play03' },
                            {resourceType: 'v', title: 'How to Play Jiu-Jitsu With Your Kids',  author: 'Gracie', focus: '', sharpFocus: '', description : '',  link: 'https://www.youtube.com/results?search_query=How+to+play+jiu-jitsu+with+your+kids+Part+Gracie+Ohio', category:'BJJ Kids', buttonTitle: '', imgPath: 'BJJ-Kids-Play02' },

                            



                            {resourceType: 'v', title: 'Mikey and The Dragons - Jocko',  author: 'Jocko', focus: 'Official Video (Animated audio book Summary)', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=C-s4t9Jd1jo', category:'Kids Books', buttonTitle: '', imgPath: 'jocko_mikey-summary-small' },
                            {resourceType: 'v', title: 'Mikey and The Dragons - Jocko',  author: 'Jocko', focus: 'Official Video (Animated audio book - FULL BOOK)', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=WL4Y0pZMZ9M&t=131s', category:'Kids Books', buttonTitle: '', imgPath: 'jocko_mikey' },
                            {resourceType: 'v', title: 'Karate Kid vs Gymnastics Kid Challenge - You Decide The Winner',  author: '', focus: 'Just for fun! :)', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=-m6X3uAt5j0&t=540s', category:'Kids Fun', buttonTitle: '', imgPath: 'GymnasticsVsKarate' },





                            {resourceType: 'v',
                            title: '(1/2) Karate Japan vs Italy. Final Male Team Kata. WKF World Karate Champions 2012. 空手日本',
                            author: 'author', focus: '', sharpFocus: '',
                            description : '',
                            link: 'https://www.youtube.com/watch?v=D50AVVpiAIg',
                            category: 'category', buttonTitle: '',
                            imgPath: 'Karate-Team'},


                            {resourceType: 'v', title: 'How to perform 3 easy BJJ submissions from mount',  author: '',
                            focus: 'Excellent instructions!', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/watch?v=rDzThK7WAM8&t=37s', category:'bjj.kids', buttonTitle: '',
                            imgPath: 'bjj-Kids-Eleni-3Submissions' },



                            {resourceType: 'v', title: 'How to improve your BJJ game with the Armbar to Triangle Submission',  author: '',
                            focus: 'Very cool moves!', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/watch?v=F92bRVHxsHA&t=1s', category:'bjj.kids', buttonTitle: '',
                            imgPath: 'bjj-Kids-Eleni-ArmBar' },


                            {resourceType: 'v', title: 'The North Face: Never Stop Exploring',  author: '',
                            focus: 'Get outside and get after it! :)', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/watch?v=5oZBIfqGvNM', category:'Mountaineering', buttonTitle: '',
                            imgPath: 'MT-NorthFace-NeverStopExploring2' },



                            {resourceType: 'v', title: '',
                            author: '', focus: 'Whoop Whoop - let\'s GO!! :)',
                            sharpFocus: '', description : 'The Best Kitesurfing Spots in the World 4K - Part 1',
                            link: 'https://www.youtube.com/watch?v=jOSsb2GgcoU', category: 'bjj1', buttonTitle: '',
                            imgPath: 'kiteSurfing-best-small' },




                          ],
          },


        {
          sectionID: 'habits',
          sectionTitle: 'Habits',
          sectionSubTitle: 'Learn to master the Power of habit',
          sectionFocus: 'Awesome tools for Mom and Dad and especially... to share with your kids! :)',
          sectionSharpFocus: '', sectionDescription : 'Make your habits & your habits will make you!',
          sectionCategory : 'Pro Skills',
          sectionItems : [
                          {resourceType: 'v', title: 'Change anything!',  author: 'Al Switzler', focus: 'Control the environment that controls YOU!', sharpFocus: '', description : 'Use skillpower over willpower | TEDxFremont', link: 'https://www.youtube.com/watch?v=3TX-Nu5wTS8&t=372s', category: 'bjj', buttonTitle: '',  imgPath: 'SkillPower' },
                          {resourceType: 'v', title: 'The Power of Habit', author: 'Charles Duhigg',  focus: '', sharpFocus: '', description : 'The Power of Habits in Life, Business & society',  link: 'https://www.youtube.com/watch?v=szgoDIkimNU&t=21s', category: 'bjj1', buttonTitle: '',  imgPath: 'PowerofHabitLecture' },
                          {resourceType: 'v', title: 'Habit Master!', author: 'Charles Duhigg',  focus: '', sharpFocus: '', description : 'How to break bad habits and form powerful new habits',   link: 'https://www.youtube.com/watch?v=W1eYrhGeffc', category: 'bjj1', buttonTitle: '',  imgPath: 'PowerofHabitcookie' },
                        ],
        },

        {
          sectionID: 'parents',
          sectionTitle: 'Parents',
          sectionSubTitle: 'Amazing talks for parents!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'Pro Skills',
          sectionItems : [
                          {resourceType: 'v',
                          title: 'Catch Me If You Can | Frank Abagnale | Talks at Google',
                          author: 'Frank Abagnale',
                          focus: 'Two talks in one! In addition to his fascinating life story, as a bonus, Mr. Abagnale delivers an awesome talk on what fatherhood is all about - outstanding!  The fatherhood talk  starts at 21.46 min',
                          sharpFocus: '',
                          description : 'Fascinating speech!!',
                          link: 'https://www.youtube.com/watch?v=vsMydMDi3rI', category: 'bjj', buttonTitle: '',
                          imgPath: 'parents-Frank-Abagnale-01' },


                          {resourceType: 'v',
                          title: 'What Every Parent Needs to Know | Jordan Peterson',
                          author: 'Jordan Peterson',
                          focus: 'Help your kids to become stronger by interacting with them, answering their questions and exposing them to reality vs \"protecting them\"',
                          sharpFocus: 'If you don\'t pay attention to your kids, participate with them and answer their questions... someone else will.',
                          description : '',
                          link: 'https://www.youtube.com/watch?v=P5gP-LpYg_k', category: 'bjj', buttonTitle: '',
                          imgPath: 'parents-Jordan-Peterson-Parenting01' },


                          {resourceType: 'v',
                          title: ' Advice for Women Starting BJJ from Female Purple Belt & Gym Owner',
                          author: 'ChewJitsu',
                          focus: 'Insightful, empowering, fantastic & fun!', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=ChewJitsu+women+starting+bjj', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Chewy-Women-in-BJJ' },




                        ],
        },

        {
          sectionID: 'warrior-kid',
          sectionTitle: 'Warrior Kids',
          sectionSubTitle: 'Way of The Warrior Kid by Jocko Willink',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Outstanding! :)',
          sectionCategory : 'BJJ',
          sectionItems : [
                            {resourceType: 'v', title: 'The Game', author: '',  focus: 'Playing “the game of life” with integrity to become a TRUE champion! :)', sharpFocus: '', description : 'If you like this story, you should definitely checkout Jocko\'s books & videos!', link: 'https://www.youtube.com/watch?v=7hT2CYFMMJs&t=229s', category: 'bjj', buttonTitle: 'Watch this video', imgPath: 'jocko_thegame'},


                            {resourceType: 'b', title: 'From Wimpy to Warrior the Navy SEAL Way: A Novel', author: 'Jocko',  focus: '', sharpFocus: '', description : 'Book 1',  link: 'https://www.amazon.com/Way-Warrior-Kid-Wimpy-Novel/dp/1250158613', category: 'bjj1', buttonTitle: 'Checkout this book',  imgPath: 'jocko_wayofwarriorkid-red' },
                            {resourceType: 'b', title: 'Mark\'s Mission', author: 'Jocko',  focus: '', sharpFocus: '', description : 'Book 2',  link: 'https://www.amazon.com/Marcs-Mission-Way-Warrior-Novel/dp/1250294436', category: 'bjj1', buttonTitle: 'Checkout this book',  imgPath: 'jocko_WWK-MarksMission' },
                            {resourceType: 'b', title: 'Where there\'s a will...', author: 'Jocko',  focus: '', sharpFocus: '', description : 'Book 3',  link: 'https://www.amazon.com/Way-Warrior-Kid-Where-theres/dp/1942549482', category: 'bjj1', buttonTitle: 'Checkout this book',  imgPath: 'jocko_WWK-WarriorKid3' },
                            {resourceType: 'b', title: 'Way of The Warrior Kid FIELD MANUAL', author: 'Jocko',  focus: 'THE ULTIMATE GUIDE TO BECOMING A WARRIOR KID!', sharpFocus: '', description : 'Book 4',  link: 'https://www.amazon.com/Way-Warrior-Kid-Field-Manual/dp/1942549660', category: 'bjj1', buttonTitle: 'Checkout this book',  imgPath: 'jocko_WWK-WarriorKid4' },
                            {resourceType: 'b', title: 'Way of the Warrior Kid', author: 'Jocko',  focus: '', sharpFocus: '', description : 'The Coloring Book! A colorful and visual way to get your kids on the path',  link: 'https://www.amazon.com/Way-Warrior-Kid-Coloring-Exercising/dp/1942549490/', category: 'bjj1',  buttonTitle: 'Checkout this book', imgPath: 'jocko_WWK-ColoringBook' },


                            {resourceType: 'v', title: 'Warrior Kid Podcast #1', author: 'Jocko',  focus: 'The first of many outstanding podcasts!', sharpFocus: '', description : 'Ask Uncle Jake',   link: 'https://www.youtube.com/watch?v=RJCl5lJHChI&t=297s', category: 'bjj1', buttonTitle: '',  imgPath: 'jocko_warriorkidpodcast' },
                            {resourceType: 'v', title: 'Warrior Kid Podcast #7', author: 'Jocko',  focus: 'SOLID - BJJ training advice  @ 9:17 and Dealing with difficult parents/boss @ 9:40', sharpFocus: '', description : 'Ask Uncle Jake',   link: 'https://www.youtube.com/watch?v=ulUVJfVOy5s&t=500s', category: 'bjj1', buttonTitle: '',  imgPath: 'jocko_warriorkidpodcast-7' },


                            {resourceType: 'v', title: 'Jocko & The Warrior Kid Book', author: 'Jocko',  focus: 'Jocko talking about the warrior kid book', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=UdDT5KEcAu0&t=128s', category: 'bjj1', buttonTitle: '',  imgPath: 'Jocko_talks2' },

                            {resourceType: 'v', title: 'Mikey and The Dragons',  author: 'Jocko', focus: 'Official Video (Animated audio book Summary)', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=C-s4t9Jd1jo', category:'Kids Books', buttonTitle: '', imgPath: 'jocko_mikey-summary-small' },
                            {resourceType: 'v', title: 'Mikey and The Dragons',  author: 'Jocko', focus: 'Official Video (Animated audio book - FULL BOOK)', sharpFocus: '', description : '',  link: 'https://www.youtube.com/watch?v=WL4Y0pZMZ9M&t=131s', category:'Kids Books', buttonTitle: '', imgPath: 'jocko_mikey' },
                          ],
          },


        {
          sectionID: 'martial-arts-bjj',
          sectionTitle: 'Martial Arts: BJJ',
          sectionSubTitle: 'Martial Arts Inspiration',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Fun, inspiring and important BJJ presentations',
          sectionCategory : 'Bjj Inspiration',
          sectionItems : [
                          {resourceType: 'v', title: '',  author: 'Ruotolo Twins', focus: 'An awesome positive mental attitude and perspective on competition.', sharpFocus: '', description : 'Inspired By Results!', link: 'https://www.youtube.com/watch?v=0lQm2pcTaYo', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotolotTwinsInspiredByResults'},
                          {resourceType: 'v', title: '',  author: 'Ruotolo Twins', focus: 'Their story...', sharpFocus: '', description : 'THE RUOTOLO TWINS',   link: 'https://www.youtube.com/watch?v=O6HZLlDqweI', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotolotTwins' },
                          {resourceType: 'v', title: 'Akira', author: 'Akira', focus: 'She does a great job explaining her journey and highlights humility.  \"win some, learn some\"', sharpFocus: 'Above all - stay in the game! :)', description : 'Checkout Akira!  She is a 12-year-old Jiu-Jitsu champion!',  link: 'https://www.youtube.com/watch?v=1DmoNf8m1VI', category: 'bjj1', buttonTitle: '',  imgPath: '12YearOldBJJ' },
                          {resourceType: 'v', title: '',  author: 'Nya Ruotolo', focus: 'Love it - Nya is FIRED UP!! :)', sharpFocus: '', description : 'Nya Ruotolo 2014',  link: 'https://www.youtube.com/watch?v=OsrNHnjxw5E', category: 'bjj1', buttonTitle: '',  imgPath: 'NyaRuotolo2014' },
                          {resourceType: 'v', title: '',  author: 'Nya Ruotolo', focus: 'Go Nya!', sharpFocus: '', description : 'Nya Ruotolo / Rickson Gracie CUP 2016',  link: 'https://www.youtube.com/watch?v=2NxJtEuaMgc', category: 'bjj1', buttonTitle: '',  imgPath: 'NyaRuotoloRicksonGracieCUP2016' },
                          {resourceType: 'v', title: 'U.S. National KIDS', author: '', focus: 'Checkout the fun music rhythm & timing!', sharpFocus: '', description : 'Jiu-Jitsu Championship - Highlights',  link: 'https://www.youtube.com/watch?v=gJHGK9Fun1E&list=PLqmv5MRYcVty_TNQi1u6d3-BsunSFjcnj&index=2&t=0s', category: 'bjj1', buttonTitle: '',  imgPath: 'usnationals' },


                          {resourceType: 'v', title: 'EVERY Child Needs Jiu-Jitsu', author: 'Gracie', focus: 'More confidence, less stress, better grades and life success!', sharpFocus: '', description : 'Why EVERY Child Needs Jiu-Jitsu',  link: 'https://www.youtube.com/watch?v=ybQ__WdAqvE&list=PLqmv5MRYcVty_TNQi1su6d3-BsunSFjcnj&index=6&t=292s', category: 'bjj1', buttonTitle: '',  imgPath: 'everyKidBJJ' },


                          {resourceType: 'v', title: 'Women\'s Self-defense', author: 'Gracie', focus: 'Empowering!', sharpFocus: '', description : 'Women\'s Self-defense That Actually Works! (Gracie Jiu-Jitsu)',  link: 'https://www.youtube.com/watch?v=ybQ__WdAqvE&list=PLqmv5MRYcVty_TNQi1su6d3-BsunSFjcnj&index=6&t=292s', category: 'bjj1', buttonTitle: '',  imgPath: 'Gracie_women' },




                          {resourceType: 'v', title: 'Stay in the game',  author: 'Gracie', focus: 'Stay in the game! :)', sharpFocus: '', description : ' Why Kids Quit Jiu-Jitsu (& How to Prevent it!)',   link: 'https://www.youtube.com/watch?v=GEMJbc0mrxU&t=402s', category: 'bJJ Tips', buttonTitle: '',  imgPath: 'Kidsbjj-stayInTheGame' },


                          {resourceType: 'v',
                          title: ' Advice for Women Starting BJJ from Female Purple Belt & Gym Owner',
                          author: 'ChewJitsu',
                          focus: 'Insightful, empowering, fantastic & fun!', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=ChewJitsu+women+starting+bjj', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Chewy-Women-in-BJJ' },


                          {resourceType: 'v',
                          title: 'Jocko Willink on Conor McGregor, BJJ & The UFC',
                          author: 'Jocko',
                          focus: 'BJJ is a SUPER POWER', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=Jocko+Willink+on+Conor+McGregor%2C+BJJ+%26+The+UFC', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-BJJ-Power' },







                          {resourceType: 'v', title: 'Arm in De La Riva to triangle - Ruotolo Brothers',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=bQoZ93_RhCg', category: 'bJJ Tips', buttonTitle: '',  imgPath: 'RuotoloBrothers-DeLaRivatriangle' },
                          {resourceType: 'v', title: 'Single leg defense to submission - Ruotolo Brothers',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=Ddskc6Dcwts', category: 'bJJ Tips', buttonTitle: '',  imgPath: 'RuotoloBrothers-SingleLeg' },
                          {resourceType: 'v', title: 'Ruotolo Twins Exhibition Roll at Studio 540',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=sNXwpWCHBsw&t=30s', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotoloBrothers-Expo-Small' },
                          {resourceType: 'v', title: 'Ruotolo Twins BJJ Highlights',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=d99bXkwvVYY&t=168s', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotoloBrothers-Highlights-small' },
                          {resourceType: 'v', title: 'Kade Ruotolo + Leticia Ribiero Exhibition Roll',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=02XeW_L9ixs', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotoloBrothers-Expo-Leticia-small' },
                          {resourceType: 'v', title: '16 years old Purple Belts after training Jiu Jitsu for almost 14 years',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=Oao_d9qrD_I&t=200s', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotoloBrothers-Belt-promo-small'},
                          {resourceType: 'v', title: 'Ruotolo Brothers',  author: '', focus: 'Passion, hardwork, focus, respect & fun! :)', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=lVrADpMh6Xo', category: 'bjj1', buttonTitle: '',  imgPath: 'RuotoloBrothers-Small' },


                          {resourceType: 'v', title: 'How to perform 3 easy BJJ submissions from mount',  author: '',
                          focus: 'Excellent instructions!', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/watch?v=rDzThK7WAM8&t=37s', category:'bjj.kids', buttonTitle: '',
                          imgPath: 'bjj-Kids-Eleni-3Submissions' },



                          {resourceType: 'v', title: 'How to improve your BJJ game with the Armbar to Triangle Submission',  author: '',
                          focus: 'Very cool move!', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/watch?v=F92bRVHxsHA&t=1s', category:'bjj.kids', buttonTitle: '',
                          imgPath: 'bjj-Kids-Eleni-ArmBar' },









                        ],
        },


           // JUDO
          // Ronda Rousey dominates for historic judo bronze at 2008 Beijing Olympics | NBC Sports
          // https://www.youtube.com/watch?v=NZHy8vG6yCA



        // Local dojos
        /*
            Sunday 11am open mat
            Saturday 8am

            -------------------------------------
            https://fulcrumjiujitsuacademy.com
            -------------------------------------
        */

       {
        sectionID: 'martial-arts-katate',
        sectionTitle: 'Shotokan Katate',
        sectionSubTitle: 'Checkout the razor sharp precision of shotokan karate!',
        sectionFocus: '',
        sectionSharpFocus: '', sectionDescription : '',
        sectionCategory : 'Bjj Inspiration',
        sectionItems : [
                        {resourceType: 'v', title: 'Heian-shodan by Mahiro (4-year-old girl)　四歳女の子の平安初段',  author: '', focus: '', sharpFocus: '', description : '', link: 'https://www.youtube.com/watch?v=ga_v9p0X94U', category: 'bjj1', buttonTitle: '',  imgPath: 'Karate-Girl-4yrs'},
                        {resourceType: 'v', title: 'Mahiro(5-year-old girl) practicing kihon for kyu exam 5歳の女の子、基本練習中',  author: 'author', focus: '', sharpFocus: '', description : '', link: 'https://www.youtube.com/watch?v=EmVMr0POsE0&t=35s', category: 'category', buttonTitle: '',  imgPath: 'Karate-Girl-5yrs-exam'},
                        {resourceType: 'v', title: 'JKA Heian-godan by 7 year-old-boy 平安五段練習中',  author: 'author', focus: '', sharpFocus: '', description : '', link: 'https://www.youtube.com/watch?v=T98xLcI6jaw', category: 'category', buttonTitle: '',  imgPath: 'Karate-Boy-7yr'},
                        {resourceType: 'v', title: 'Enpi and Kanku-dai, Karate Girl! 天才空手少女の見事な型！（エンピ、観空大）',  author: 'author', focus: '', sharpFocus: '', description : '', link: 'https://www.youtube.com/watch?v=rf7ZFj_cSeQ&t=37s', category: 'category', buttonTitle: '',  imgPath: 'Karate-Girl-Empi-comp'},


                        {resourceType: 'v',
                        title: '(1/2) Karate Japan vs Italy. Final Male Team Kata. WKF World Karate Champions 2012. 空手日本',
                        author: 'author', focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/watch?v=D50AVVpiAIg',
                        category: 'category', buttonTitle: '',
                        imgPath: 'Karate-Team'},



                        {resourceType: 'v',
                        title: '(1/2) Karate Japan vs Italy. Final Male Team Kata. WKF World Karate Champions 2012. 空手日本',
                        author: 'author', focus: '', sharpFocus: '', description : '',
                        link: 'https://www.youtube.com/watch?v=7HAvpHAZsGQ', category: 'category', buttonTitle: '',
                        imgPath: 'karate-Team-Women'},


                        {resourceType: 'v',
                        title: 'Okinawa Japan: 23 Things to DO & KNOW! | Watch Before You Go!',
                        author: 'author', focus: 'The birthplace of Karate', sharpFocus: 'Very tropical, looks amazing let\'s GO!!', description : '',
                        link: 'https://www.youtube.com/watch?v=OgoK3XxJhbQ', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-Okinawa-01'},






                        // KATAS
                        {resourceType: 'v',
                        title: 'Heian Shodan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Heian+Shodan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Heian-Shodan'},

                        {resourceType: 'v',
                        title: 'Heian Nidan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Heian+Nidan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Heian-Nidan'},

                        {resourceType: 'v',
                        title: 'Heian Sandan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Heian+Sandan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Heian-Sandan'},

                        {resourceType: 'v',
                        title: 'Heian Yondan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Heian+Yondan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Heian-Yondan'},







                        {resourceType: 'v',
                        title: 'Heian Godan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Heian+Godan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Heian-Godan'},


                        {resourceType: 'v',
                        title: 'Tekki Shodan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Tekki+Shodan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Tekki-Shodan'},

                        {resourceType: 'v',
                        title: 'Tekki Nidan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Tekki+Nidan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Tekki-Nidan'},

                        {resourceType: 'v',
                        title: 'Tekki Sandan',
                        author: 'author',
                        focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/results?search_query=shotokan+karate+Tekki+Sandan', category: 'category', buttonTitle: '',
                        imgPath: 'Karate-K-Tekki Sandan'},



                        // {resourceType: 'v',
                        // title: 'TITLE',
                        // author: 'author',
                        // focus: '', sharpFocus: '',
                        // description : '',
                        // link: 'HTTP', category: 'category', buttonTitle: '',
                        // imgPath: 'THEIMAGE'},

                        // Heian Shodan
                        // https://www.youtube.com/results?search_query=Heian+Shodan


                        // Heian Nidan - Shotokan Karate
                        // https://www.youtube.com/watch?v=6Hc1NMdjU9U&t=15s


                        // Heian Sandan - Shotokan Karate
                        // https://www.youtube.com/watch?v=8OI-FTV3-4o

                        // Heian Yondan - Shotokan Karate
                        // https://www.youtube.com/watch?v=HrAiTCcatbY

                        // Heian Godan - Shotokan Karate
                        // https://www.youtube.com/watch?v=dBFe54glhTs


                        // Tekki Shodan
                        // https://www.youtube.com/watch?v=VAkA5zAosC4


                        // Tekki Nidan - Shotokan Karate
                        // https://www.youtube.com/watch?v=9uqw0g3E4BY

                        // Tekki Sandan - Shotokan Karate
                        // https://www.youtube.com/watch?v=-bmF_F2bDoc


                      // tools and toys
                      // https://www.spinsei.com/


                      ],
      },





        {
          sectionID: 'martial-arts-muay-thai',
          sectionTitle: 'Muay Thai',
          sectionSubTitle: 'Basic Muay Thai skills and drills - Very pragmatic!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'Muay Thai',
          sectionItems : [


                          {resourceType: 'v', title: 'Partner Combination Drills for Muay Thai',  author: 'Pentagon MMA', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=_RcyeWbBmdM&t=154s', category: 'bjj1', buttonTitle: '',  imgPath: 'MuayThai-PartnerCombos-small' },
                          {resourceType: 'v', title: 'Muay Thai Technique - Basic Punches',  author: 'Pentagon MMA', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=Ejz9HJHQZag', category: 'bjj1', buttonTitle: '',  imgPath: 'MuayThai-BasicPunches-Small' },

                          {resourceType: 'v', title: 'Practice w/ a Friend: Muay Thai Partner Flow Drill',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=TCF31GEwKy4', category: 'bjj1', buttonTitle: '',  imgPath: 'MuayThai-FlowDrill' },

                        ],
        },



        {
          sectionID: 'martial-arts-taekwondo',
          sectionTitle: 'Taekwondo',
          sectionSubTitle: 'Athletics, flexibility and speed!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'Bjj Inspiration',
          sectionItems : [
                          {resourceType: 'v', title: 'Amazing Taekwondo stunts in 4K Slow Motion',  author: '', focus: 'Superstar Athletics!', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=jlzVmOUP1is&t=403s', category: 'bjj1', buttonTitle: '',  imgPath: 'SlowMo-Takewondo-stunts-c-small' },
                          {resourceType: 'v', title: 'Awesome Taekwondo Skills',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=TKEbws4QhEk', category: 'bjj1', buttonTitle: '',  imgPath: 'Takewondo-skills-awesome' },
                          {resourceType: 'v', title: 'Taekwondo Speed and Agility drills by Samery Moras - 4th degree black belt & 6x collegiate national team member',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=MKaGQV8KeP8', category: 'bjj1', buttonTitle: '',  imgPath: 'Takewondo-5drills' },


                        ],
        },

        {
          sectionID: 'womens-self-defense',
          sectionTitle: 'Self-Defense Concepts',
          sectionSubTitle: 'The more the better!  Improve your perspective on Self-Defense! :)',
          sectionFocus: 'BJJ can provide a SHARP EDGE against a bigger, stronger opponent!',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'Bjj Inspiration',
          sectionItems : [

                          {resourceType: 'v',
                          title: 'Women\'s Self-defense',
                          author: 'Gracie', focus: 'Empowering!', sharpFocus: '',
                          description : 'Women\'s Self-defense That Actually Works! (Gracie Jiu-Jitsu)',
                          link: 'https://www.youtube.com/watch?v=ybQ__WdAqvE&list=PLqmv5MRYcVty_TNQi1su6d3-BsunSFjcnj&index=6&t=292s', category: 'bjj1', buttonTitle: '',
                          imgPath: 'Gracie_women' },

                          {resourceType: 'v',
                            title: 'Why Jiu-Jitsu is the BEST Self-Defense for Women!',
                            author: '', focus: '', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/watch?v=FfobapB8bTA', category: 'bjj1', buttonTitle: '',
                            imgPath: 'Women-SelfDefense-02' },


                            {resourceType: 'v',
                            title: ' Self-Defense Basics Course',
                            author: 'Ando Mierzwa',
                            focus: 'Awesome!', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/results?search_query=ando+mierzwa+self+defense+course', category: 'bjj1', buttonTitle: '',
                            imgPath: 'MartialArts-SelfD-Ando' },


                            {resourceType: 'v',
                            title: ' Advice for Women Starting BJJ from Female Purple Belt & Gym Owner',
                            author: 'ChewJitsu',
                            focus: 'Insightful, empowering, fantastic & fun!', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/results?search_query=ChewJitsu+women+starting+bjj', category: 'bjj1', buttonTitle: '',
                            imgPath: 'MartialArts-Thinking-Chewy-Women-in-BJJ' },




                        ],
        },

        {
          sectionID: 'new-bjj',
          sectionTitle: 'I am new / thinking of trying BJJ',
          sectionSubTitle: 'Any age works great!  Shop around, do some trial classes and see what school fits best for you.',
          sectionFocus: 'BJJ can provide a SHARP EDGE against a bigger, stronger opponent!',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'BJJ New',
          sectionItems : [


                          {resourceType: 'v',
                          title: 'Joe Rogan | Jiu-jitsu is a Gentle Art w/Jean Jaques Machado', author: 'JoeRogan',
                          focus: 'Become a SUPERHERO & be your opponent\'s caretaker! :)',
                          sharpFocus: 'Develop the right mindset and skills to protect yourself and others - including the very confused/ drunk/ depressed/ irrational etc... attacker!  ( Also See the 4 Agreements Book to help develop a constructive mindset/perspective )! :)', description : '',
                          link: 'https://www.youtube.com/results?search_query=Joe+Rogan++Jiu-jitsu+is+a+Gentle+Art+w%2FJean+Jaques+Machado', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-JoeRogan-Machado-BJJGentle' },


                          {resourceType: 'v',
                            title: 'HILARIOUS!!! Ed O\'Neill\'s VERY First Class at the Gracie Jiu-Jitsu Academy',
                            author: '', focus: '', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/results?search_query=Ed+O%27Neill%27s+VERY+First+Class+at+the+Gracie+Jiu-Jitsu+Academy', category: 'bjj1', buttonTitle: '',
                            imgPath: 'MartialArts-Thinking-New-Ed-ONEILL' },

                            {resourceType: 'v',
                            title: ' Advice for Women Starting BJJ from Female Purple Belt & Gym Owner',
                            author: 'ChewJitsu',
                            focus: 'Insightful, empowering, fantastic & fun!', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/results?search_query=ChewJitsu+women+starting+bjj', category: 'bjj1', buttonTitle: '',
                            imgPath: 'MartialArts-Thinking-Chewy-Women-in-BJJ' },



                            {resourceType: 'v',
                            title: 'Why Jiu-Jitsu is the BEST Self-Defense for Women!',
                            author: '', focus: '', sharpFocus: '', description : '',
                            link: 'https://www.youtube.com/watch?v=FfobapB8bTA', category: 'bjj1', buttonTitle: '',
                            imgPath: 'Women-SelfDefense-02' },

                            {resourceType: 'v',
                            title: 'Women\'s Self-defense',
                            author: 'Gracie', focus: 'Empowering!', sharpFocus: '',
                            description : 'Women\'s Self-defense That Actually Works! (Gracie Jiu-Jitsu)',
                            link: 'https://www.youtube.com/watch?v=ybQ__WdAqvE&list=PLqmv5MRYcVty_TNQi1su6d3-BsunSFjcnj&index=6&t=292s', category: 'bjj1', buttonTitle: '',
                            imgPath: 'Gracie_women' },


                        ],
        },



        {
          sectionID: 'martial-arts-thinking',
          sectionTitle: 'Martial Arts: Important Ideas',
          sectionSubTitle: 'Important Ideas',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : '',
          sectionItems : [


                          {resourceType: 'v',
                          title: 'Joe Rogan | Jiu-jitsu is a Gentle Art w/Jean Jaques Machado', author: 'JoeRogan',
                          focus: 'Become a SUPERHERO & be your opponent\'s caretaker! :)', sharpFocus: 'Develop the right mindset and skills to protect yourself and others - including the very confused/ drunk/ depressed/ irrational etc... attacker!  ( Also See the 4 Agreements Book to help develop a constructive mindset/perspective )! :)', description : '',
                          link: 'https://www.youtube.com/results?search_query=Joe+Rogan++Jiu-jitsu+is+a+Gentle+Art+w%2FJean+Jaques+Machado', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-JoeRogan-Machado-BJJGentle' },


                          {resourceType: 'v',
                          title: 'Chuck Norris - A great friend,  very gracious - exceptional guy! | Joe Rogan', author: 'JoeRogan',
                          focus: 'Chuck sets the example!', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=joe+rogan+machado+chuck+norris', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-JoeRogan-ChuckNorris' },


                          {resourceType: 'v',
                          title: 'Jocko Willink and Dean Lister Talk about Benefits of BJJ, Grappling grips, GI vs Nogi',
                          author: 'Jocko',
                          focus: 'BJJ is... truth!', sharpFocus: 'BJJ provides the rich soil of life to help you grow and become strong on multiple levels!', description : '',
                          link: 'https://www.youtube.com/results?search_query=Jocko+Willink+and+Dean+Lister+Talk+about+Benefits+of+BJJ%2C', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-The-Way' },


                          {resourceType: 'v',
                          title: 'Jocko Willink on Conor McGregor, BJJ & The UFC',
                          author: 'Jocko',
                          focus: 'BJJ is a SUPER POWER', sharpFocus: 'Become a \"sheepdog\"!', description : '',
                          link: 'https://www.youtube.com/results?search_query=Jocko+Willink+on+Conor+McGregor%2C+BJJ+%26+The+UFC', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-BJJ-Power' },








                          {resourceType: 'v',
                          title: 'BJJ Coaches Aren\'t There To Spoon Feed You', author: 'Chewjitsu',
                          focus: 'Discover it and learn it by DOING IT... make it YOUR knowledge and skill by trying, failing, learning and winning.  If you keep after it and stay in the game, you WIN because you LEARN!',
                          sharpFocus: 'Always find a way to stay in the game! :)', description : '',
                          link: 'https://www.youtube.com/results?search_query=chewjitsu+Coaches+Spoon+Feed+You', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-RealLearning' },

                          {resourceType: 'v',
                          title: 'Your BJJ Coaches Are Humans too', author: 'Chewjitsu',
                          focus: '(Be sure to build a relationship with your coach and ask plenty of questions)', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=chewjitsu+BJJ+Coaches+Are+Humans+too', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-AskYourCoach' },

                          {resourceType: 'v',
                          title: '7 Years of BJJ and I Got Smashed by a Wrestler on his 1st Week', author: 'Chewjitsu',
                          focus: '', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=chewjitsu+smashed', category: '', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Got-Smashed-by-a-Wrestler' },



                          {resourceType: 'v',
                          title: 'Jiu Jitsu is a SUPERPOWER: Joe Rogan and Jocko Willink',
                          author: 'Jocko',
                          focus: '', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=joe+rogan+Jocko+Willink+bjj+super+power',
                          category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-BJJ-SuperPower' },






                        ],
        },


        {
          sectionID: 'jocko-adults',
          sectionTitle: 'Jocko for Adults & Older Kids',
          sectionSubTitle: 'NYT Best selling author!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Awesome leadership principals for work, family and personal performance!',
          sectionCategory : 'Awesome Jocko Videos',
          sectionItems : [
                          {resourceType: 'v', title: '', author: '',  focus: '', sharpFocus: '', description : 'WHAT IS EXTREME OWNERSHIP? - Jocko Willink on London Real', link: 'https://www.youtube.com/watch?v=yGBj2xAnqE8&t=122s', category: '', buttonTitle: '',  imgPath: 'Jocko-OwnIt' },

                          {resourceType: 'b', title: 'EXTREME OWNERSHIP!', author: 'Jocko',  focus: 'NYT Best seller!', sharpFocus: '', description : '', link: 'https://www.amazon.com/Extreme-Ownership-Jocko-Willink/dp/1250270960/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=', category: '', buttonTitle: '',  imgPath: 'Book-ExtreamOwnership' },


                          {resourceType: 'v', title: 'The Importance of Having Thick Skin - Jocko Willink and Jordan Peterson', author: 'Jocko',  focus: '', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/watch?v=futHL4W4E4s', category: '', buttonTitle: '',
                          imgPath: 'Jocko-thickSkin' },


                          {resourceType: 'v', title: 'Why Being Late is UNACCEPTABLE - Jocko Willink and Echo Charles', author: 'Jocko',  focus: '', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/watch?v=UlgTYXPzqhg&t=129s', category: '', buttonTitle: '',
                          imgPath: 'Jocko-NeverLate' },


                          {resourceType: 'v',
                          title: 'Jocko Willink and Dean Lister Talk about Benefits of BJJ, Grappling grips, GI vs Nogi',
                          author: 'Jocko',
                          focus: 'BJJ is... truth!', sharpFocus: 'BJJ provides the rich soil of life to help you grow and become strong on multiple levels!', description : '',
                          link: 'https://www.youtube.com/results?search_query=Jocko+Willink+and+Dean+Lister+Talk+about+Benefits+of+BJJ%2C', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-The-Way' },



                          {resourceType: 'v',
                          title: 'Jocko Willink on Conor McGregor, BJJ & The UFC',
                          author: 'Jocko',
                          focus: 'BJJ is a SUPER POWER', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=Jocko+Willink+on+Conor+McGregor%2C+BJJ+%26+The+UFC', category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-BJJ-Power' },




                          {resourceType: 'v',
                          title: 'Jiu Jitsu is a SUPERPOWER: Joe Rogan and Jocko Willink',
                          author: 'Jocko',
                          focus: '', sharpFocus: '', description : '',
                          link: 'https://www.youtube.com/results?search_query=joe+rogan+Jocko+Willink+bjj+super+power',
                          category: 'bjj1', buttonTitle: '',
                          imgPath: 'MartialArts-Thinking-Jocko-BJJ-SuperPower' },





                        ],
        },



        {
          sectionID: 'reading-adventures',
          sectionTitle: 'Reading Adventures',
          sectionSubTitle: 'Adventures in reading for kids ( books ) and listening ( audio books )',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Classics filled with adventure!',
          sectionCategory : 'reading',
          sectionItems : [


                        {resourceType: 'a', title: 'Schoolhouse Mystery BCC #10- Ch. 1 - on YouTube.com',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=5aOlxALn_P8', category: '', buttonTitle: '',  imgPath: 'BOOKS-boxcar-01-Schoolhouse' },
                        {resourceType: 'b', title: 'Boxcar Children books on Amazon.com',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.amazon.com/Boxcar-Children-Bookshelf-Mysteries-Books/dp/0807508551/', category: '', buttonTitle: '',  imgPath: 'BOOKS-boxcar-02-website' },
                        {resourceType: 'w', title: 'BoxcarChildren.com - official website',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.boxcarchildren.com/', category: '', buttonTitle: '',  imgPath: 'BOOKS-boxcar-03-island' },

                        {resourceType: 'w', title: 'Roald Dahl - Official Website',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.roalddahl.com/', category: '', buttonTitle: '',  imgPath: 'BOOKS-Roald-Dahl-website' },
                        {resourceType: 'a', title: 'The Enormous Crocodile by Roald Dahl - Read by Roger Blake - Audiobook on YouTube ( there are many more - this is just a sample )',  author: '', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=HpmV-mDkJjA', category: '', buttonTitle: '',  imgPath: 'BOOKS-Roald-Dahl-croc' },

                        ],
        },

        {
          sectionID: 'writing',
          sectionTitle: 'Writing, Journaling, Focus and Study',
          sectionSubTitle: 'Discover the power writing - the most powerful tool of all!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Journaling will help strengthen your writing skills and sharpen your mind!  Also, learn to focus and study to accomplish your dreams!',
          sectionCategory : 'ocean',
          sectionItems : [

            {resourceType: 'v', title: '',  author: '', focus: 'Power up and direct your subconscious to your benefit!', sharpFocus: '',
            description : 'The Benefits Of Journaling',
            link: 'https://www.youtube.com/watch?v=n48E_Ffn1Rg', category: 'bjj1', buttonTitle: '',
            imgPath: 'Writing-Journaling' },


            {resourceType: 'v', title: '',  author: '', focus: 'Learn to write / learn to THINK! Writing IS... thinking.  Therefore, if you learn to write, think and speak well, you will have developed powerful tools to help you accomplish amazing things!', sharpFocus: '',
            description : 'Jordan Peterson on the Power of Writing',
            link: 'https://www.youtube.com/watch?v=bfDOoADCfkg', category: 'bjj1', buttonTitle: '',
            imgPath: 'Writing-JP-PowerOfWriting' },


            {resourceType: 'v', title: '',  author: '', focus: 'Very powerful!', sharpFocus: '',
            description : 'How to Achieve Your Most Ambitious Goals | Stephen Duneier | TEDxTucson',
            link: 'https://www.youtube.com/watch?v=TQMbvJNRpLE', category: 'bjj1', buttonTitle: '',
            imgPath: 'Ted-AchieveYourMostAmbitiousGoals' },

                        ],
        },







        {
          sectionID: 'science-math-engineering',
          sectionTitle: 'Math, Science, Engineering',
          sectionSubTitle: 'Expand your mind!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : '',
          sectionCategory : 'math,science,Engineering',
          sectionItems : [


                         {resourceType: 'v',
                         title: 'SciShow Kids',  author: '',
                         focus: 'Lots of Fun Science Projects!', sharpFocus: '', description : '',
                         link: 'https://www.youtube.com/channel/UCRFIPG2u1DxKLNuE3y2SjHA',
                         category: '',
                         buttonTitle: '',  imgPath: 'MathSciEng-SciShowKids' },


                         {resourceType: 'v', title: 'Slow Mo Guys - Exploding Spray Paint at 2500fps',  author: 'Slow Mo Guys', focus: 'There are many more super fun slow mo guys videos on youtube', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=weQkUHbr-Ww', category: 'bjj1', buttonTitle: '',  imgPath: 'slowMo-SprayPaint' },
                         {resourceType: 'v', title: 'Glitter Bomb 1.0 vs Porch Pirates',  author: '', focus: 'Package Thief vs. Glitter Bomb Trap', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=xoxhDk-hwuo', category: 'bjj1', buttonTitle: '',  imgPath: 'slowMo-GlitterBomb' },
                         {resourceType: 'v', title: 'Automatic Bullseye, MOVING DARTBOARD',  author: '', focus: 'Super fun engeering!', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=MHTizZ_XcUM', category: 'bjj1', buttonTitle: '',  imgPath: 'slowMo-Darts' },

                         {resourceType: 'v', title: 'Olympic Cyclist Vs. Toaster: Can He Power It?',  author: '', focus: 'This guy is a BEAST!!!', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=S4O5voOCqAQ', category: 'bjj1', buttonTitle: '',  imgPath: 'Bonus-CycleToaster' },


                         {resourceType: 'v', title: 'Why It\'s Almost Impossible to Ride a Bike 60 Kilometers in One Hour',  author: 'WIRED', focus: '', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=DiUE3qDnFtU&t=562s', category: 'bjj1', buttonTitle: '',  imgPath: 'Bonus-Cycle60' },

                         {resourceType: 'v', title: 'Why It\'s Almost Impossible to Spin 300 Hula Hoops At Once ',  author: 'WIRED', focus: 'Crazy fun fitness!', sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=DiUE3qDnFtU&t=562s', category: 'bjj1', buttonTitle: '',  imgPath: 'Bonus-Hoops' },




                        ],
        },




        {
          sectionID: 'global-adventures',
          sectionTitle: 'The Humanities',
          sectionSubTitle: 'Adventures in history, economics, geography, anthropology, methodology, literature, ecology, geology.',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Expand your mind and seize the day!',
          sectionCategory : 'reading',
          sectionItems : [



                        {resourceType: 'w', title: 'How Long Is the Great Wall of China?',  author: '', focus: '', sharpFocus: '',
                         description : '',   link: 'https://www.travelchinaguide.com/china_great_wall/facts/how-long.htm', category: '', buttonTitle: '',
                         imgPath: 'Global-China-wall' },


                         {resourceType: 'v', title: 'Principles For Success by Ray Dalio',  author: '',
                         focus: '(In 30 Minutes)', sharpFocus: '', description : '',
                         link: 'https://www.youtube.com/watch?v=B9XGUpQZY38&t=365s', category: '',
                         buttonTitle: '',  imgPath: 'Global-RayD-Success' },


                        {resourceType: 'v', title: 'Principles: Life and Work | Ray Dalio | Talks at Google',
                        author: '', focus: '', sharpFocus: '',
                        description : '',
                        link: 'https://www.youtube.com/watch?v=c1OoWdqbKdg&t=2307s', category: 'bjj1', buttonTitle: '',
                        imgPath: 'HUM-RayDalio-TalksAtGoogle' },





                        {resourceType: 'v', title: 'How The Economic Machine Works by Ray Dalio',
                        author: '', focus: '', sharpFocus: '', description : '',
                        link: 'https://www.youtube.com/watch?v=PHe0bXAIuk0&t=3s', category: '', buttonTitle: '',
                        imgPath: 'Global-RayD-Economics-Machine' },

                        {resourceType: 'v', title: 'Thomas Sowell -- Basic Economics',  author: '', focus: '',
                        sharpFocus: '', description : '',   link: 'https://www.youtube.com/watch?v=bOMksnSaAJ4&t=658s', category: '',
                        buttonTitle: '',
                        imgPath: 'Global-ThomasSowell-BasicEconomics' },

                        {resourceType: 'v', title: '\"Carpe diem. Seize the day.\" Dead Poets Society ( movie scene - 3 min )',
                        author: '', focus: '', sharpFocus: '', description : '',
                        link: 'https://www.youtube.com/watch?v=E5t3ZzZv8_U', category: '', buttonTitle: '',  imgPath: 'Global-DeadPoetsSociety-SeizeTheDay' },

                        ],
        },


        {
          sectionID: 'mountaineering',
          sectionTitle: 'Mountaineering',
          sectionSubTitle: 'Get outside!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Awesome Mountaineering!',
          sectionCategory : 'mountaineering',
          sectionItems : [

            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '', description : '2016/2017 Banff Mountain Film Festival World Tour (Canada/USA)',   link: 'https://www.youtube.com/watch?v=UFaGgxGsdbY', category: 'bjj1', buttonTitle: '',  imgPath: 'Banff-Film-Fest-16-17-Small-o' },
            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '', description : '2017/2018 Banff Centre Mountain Film Festival World Tour (Canada/USA)',   link: 'https://www.youtube.com/watch?v=u1S1No9JL4k&t=102s', category: 'bjj1', buttonTitle: '',  imgPath: 'Banff-Film-Fest-17-18' },
            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '', description : '2018/2019 Banff Centre Mountain Film Festival World Tour (Canada/USA)',   link: 'https://www.youtube.com/watch?v=t3KrMH57Sl4', category: 'bjj1', buttonTitle: '',  imgPath: 'Banff-Film-Fest-18-19' },
            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '', description : '2019/2020 Banff Centre Mountain Film Festival World Tour (Canada/USA)',   link: 'https://www.youtube.com/watch?v=O-DasqdQRPM&t=129s', category: 'bjj1', buttonTitle: '',  imgPath: 'Banff-Film-Fest-19-20' },


            {resourceType: 'a', title: '',  author: '', focus: '', sharpFocus: '',
              description : 'Snow blindness can sneak up on you, but prevention is easy',
              link: 'https://www.cnn.com/2021/01/28/health/snow-blindness-prevention-wellness/index.html', category: 'bjj1', buttonTitle: '',
              imgPath: 'MT-SnowBlind-small' },


              {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '',
              description : 'Kids Meet A Mountaineer | Kids Meet | HiHo Kids',
              link: 'https://www.youtube.com/watch?v=oquucfui1_k', category: 'bjj1', buttonTitle: '',
              imgPath: 'MT-KidsMeetMountaineer' },

              {resourceType: 'v', title: '',  author: '', focus: 'Advanced Mountaineering! :)', sharpFocus: '',
              description : 'Mountaineering: Height Doesn\'t Matter | National Geographic',
              link: 'https://www.youtube.com/watch?v=Dtwjj40t-No', category: 'bjj1', buttonTitle: '',
              imgPath: 'MT-Mountaineering-Advanced' },


              {resourceType: 'v', title: 'The North Face: Never Stop Exploring',  author: '',
              focus: 'Get outside and after it! :)', sharpFocus: '', description : '',
              link: 'https://www.youtube.com/watch?v=5oZBIfqGvNM', category:'bjj.kids', buttonTitle: '',
              imgPath: 'MT-NorthFace-NeverStopExploring2' },



                        ],
        },
        {
          sectionID: 'ocean-adventure',
          sectionTitle: 'Ocean Adventure',
          sectionSubTitle: 'Get outside!',
          sectionFocus: '',
          sectionSharpFocus: '', sectionDescription : 'Amazing Ocean Adventures!',
          sectionCategory : 'ocean',
          sectionItems : [

            {resourceType: 'v', title: '',
            author: '', focus: 'Whoop Whoop - let\'s GO!! :)',
            sharpFocus: '', description : 'The Best Kitesurfing Spots in the World 4K - Part 1',
            link: 'https://www.youtube.com/watch?v=jOSsb2GgcoU', category: 'bjj1', buttonTitle: '',
            imgPath: 'kiteSurfing-best-small' },

            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '',
            description : 'Ocean Kids 101 | National Geographic',
            link: 'https://www.youtube.com/watch?v=MfWyzrkFkg8', category: 'bjj1', buttonTitle: '',
            imgPath: 'Ocean-Kids-101' },


            {resourceType: 'v', title: '',  author: '', focus: '', sharpFocus: '',
            description : 'This Incredible Animation Shows How Deep The Ocean Really Is',
            link: 'https://www.youtube.com/watch?v=UwVNkfCov1k', category: 'bjj1', buttonTitle: '',
            imgPath: 'Ocean-Kids-HowDeep' },






                        ],
        },



        // Adventure Travel Section - new zealand scenery
        // https://www.bing.com/images/search?q=new+zealand+scenery


      ];
    }



} // end:class



